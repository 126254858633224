import React from "react";
import { Typography, Box } from "@material-ui/core";

function CustomErrorMessage({ message, info }) {
  return (
    <Box mt={3} mb={3}>
      <Typography
        align="center"
        color={info ? "textPrimary" : "error"}
      >
        {!info && `Đã xảy ra lỗi, vui lòng thử lại sau!`}
        {info && message}
        <br />
        {!info && <small>{message}</small>}
      </Typography>
    </Box>
  );
}

export default CustomErrorMessage;
