import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  ButtonGroup,
  IconButton,
  Collapse,
} from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import MathJax from "react-mathjax-preview";
import NotificationDelete from "./NotificationDelete";
import NotificationForm from "./NotificationForm";
import { UserGroupCode } from "../../helper";

const PUBLISH_NOTIFICATION = gql`
  mutation dispatchNotification($id: String!) {
    newNoti: dispatchNotification(id: $id) {
      _id
      state
    }
  }
`;

function NotificationDialog({ notification = {}, ...rest }) {
  const [
    { _id, title, content, createdBy, state, createdAt, target },
    setNotification,
  ] = useState(notification);
  const [publish, { error, loading }] = useMutation(PUBLISH_NOTIFICATION);
  const { enqueueSnackbar } = useSnackbar();
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handlePublish = async () => {
    try {
      const {
        data: { newNoti },
      } = await publish({
        variables: { id: _id },
      });
      setNotification((preVal) => ({
        ...preVal,
        ...newNoti,
      }));
      enqueueSnackbar("Xuất bản thành công", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Xuất bản thất bại, " + err.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Đã xảy ra lỗi, vui lòng thử lại sau!", { variant: "error" });
      console.log(error);
    }
  });

  return (
    <Dialog {...rest} fullWidth>
      {!isEdit && (
        <DialogTitle disableTypography>
          <Typography variant="h6" style={{ paddingRight: 48 }}>
            {title}
          </Typography>
          <Typography style={{ color: "grey", fontSize: "0.8rem" }}>
            {new Date(createdAt * 1).toLocaleString("vi-VN")}
            {", "}
            {createdBy && createdBy?.displayName}
            {", "}
            <b>{state === "published" ? "Đã xuất bản" : "Bản nháp"}</b>
          </Typography>
          <Typography style={{ color: "grey", fontSize: "0.8rem" }}>
            {"Người nhận: "}
            {target.map((key) => UserGroupCode[key]).join(", ")}
          </Typography>
          <IconButton
            onClick={rest.onClose}
            style={{
              position: "absolute",
              top: "6px",
              right: "6px",
            }}
          >
            <CloseIcon />
          </IconButton>
          <hr />
        </DialogTitle>
      )}
      {!isEdit && (
        <DialogContent>
          <Collapse in={!isDelete}>
            <MathJax math={content} className="ck-content" />
          </Collapse>
          <Collapse in={isDelete} mountOnEnter unmountOnExit>
            <NotificationDelete
              id={_id}
              onCompleted={rest.onClose}
              onCancelled={() => setIsDelete(false)}
            />
          </Collapse>
        </DialogContent>
      )}
      {!isEdit && (
        <DialogActions>
          {state !== "published" && !isDelete && (
            <ButtonGroup>
              <Button color="secondary" onClick={() => setIsDelete(true)}>
                Xóa
              </Button>
              <Button color="primary" onClick={() => setIsEdit(true)}>
                Chỉnh sửa
              </Button>
              <Button
                onClick={handlePublish}
                variant="contained"
                color="primary"
                disabled={loading}
                endIcon={loading ? <CircularProgress size={24} /> : <SendIcon />}
              >
                Xuất bản
              </Button>
            </ButtonGroup>
          )}
        </DialogActions>
      )}
      <Collapse in={isEdit} mountOnEnter unmountOnExit>
        <NotificationForm
          notification={{ _id, title, content, target }}
          onCompleted={(newNoti) => {
            setIsEdit(false);
            setNotification((preVal) => ({ ...preVal, ...newNoti }));
          }}
          onCancelled={() => setIsEdit(false)}
        />
      </Collapse>
    </Dialog>
  );
}

export default NotificationDialog;
