import React, { useState } from 'react';
import gql from 'graphql-tag';
import {
  Paper,
  makeStyles,
  TextField,
  FormLabel,
  FormControlLabel,
  FormControl,
  Checkbox,
  ButtonGroup,
  Button,
  CircularProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { useHistory } from 'react-router-dom';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-custom';
import { useSnackbar } from 'notistack';

const CREATE_NOTIFICATION = gql`
  mutation CreateNotification($input: NotificationInput!) {
    newNoti: createNotification(input: $input) {
      _id
    }
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($id: String!, $input: NotificationInput!) {
    newNoti: updateNotification(id: $id, input: $input) {
      _id
      title
      content
      target
    }
  }
`;

const PUBLISH_NOTIFICATION = gql`
  mutation dispatchNotification($id: String!) {
    noti: dispatchNotification(id: $id) {
      _id
      state
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 600,
    padding: 16,
    margin: 'auto',
  },
  input: {
    marginBottom: 16,
  },
  title: {
    marginBottom: 16,
  },
  editor: {
    marginBottom: 16,
  },
}));

function NotificationForm({ notification = {}, onCompleted, onCancelled }) {
  const styles = useStyles();
  const history = useHistory();
  const { _id, title = '', content = '', target = [] } = notification;
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    title,
    content,
    target: target.join(','),
  });
  const [createNotification, { loading: saving }] = useMutation(
    CREATE_NOTIFICATION,
    { refetchQueries: ['GetAllNotification'] },
  );
  const [updateNotification, { loading: updating }] = useMutation(
    UPDATE_NOTIFICATION,
  );
  const [publish, { loading: publishing }] = useMutation(PUBLISH_NOTIFICATION);
  const [publishNow, setPublishNow] = useState('0');

  const handleChangeTitle = (event) => {
    setFormData({
      ...formData,
      title: event.target.value,
    });
  };

  const handleChangeContent = (event, editor) => {
    setFormData({
      ...formData,
      content: editor.getData(),
    });
  };

  const handleChangeTarget = (event) => {
    setFormData({
      ...formData,
      target: formData.target.includes(event.target.value)
        ? formData.target.replace(event.target.value, '')
        : formData.target + ',' + event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const target = formData.target.split(',').filter((value) => value.length > 0);
    if (target.length > 0 && formData.content && formData.title) {
      try {
        const {
          data: { newNoti },
        } = _id
          ? await updateNotification({
              variables: {
                id: _id,
                input: {
                  title: formData.title,
                  content: formData.content,
                  target,
                },
              },
            })
          : await createNotification({
              variables: {
                input: {
                  title: formData.title,
                  content: formData.content,
                  target,
                },
              },
            });
        enqueueSnackbar('Lưu thành công!', { variant: 'success' });
        if (publishNow === '1') {
          try {
            await publish({
              variables: { id: newNoti._id },
            });
            newNoti.state = 'published';
            enqueueSnackbar('Xuất bản thành công!', { variant: 'success' });
          } catch (err) {
            enqueueSnackbar('Xuất bản thất bại, ' + err.message, {
              variant: 'error',
            });
          }
        }
        if (onCompleted) {
          onCompleted(newNoti);
        } else {
          history.push('/notification');
        }
      } catch (err) {
        enqueueSnackbar('Lưu thất bại, ' + err.message, { variant: 'error' });
      }
    }
  };

  return (
    <Paper className={styles.content} component="form" onSubmit={handleSubmit}>
      <TextField
        className={styles.input}
        label="Tiêu đề"
        value={formData.title}
        onChange={handleChangeTitle}
        variant="outlined"
        margin="dense"
        fullWidth
        required
      />
      <div className={styles.editor}>
        <FormLabel>Nội dung</FormLabel>
        <CKEditor
          editor={ClassicEditor}
          data={formData.content}
          onChange={handleChangeContent}
        />
      </div>
      <FormControl className={styles.input} fullWidth required>
        <FormLabel>Người nhận thông báo</FormLabel>
        <FormControlLabel
          label="Quản trị viên"
          control={
            <Checkbox
              value="admin"
              checked={formData.target.includes('admin')}
              onChange={handleChangeTarget}
            />
          }
        />
        <FormControlLabel
          label="Quản lý"
          control={
            <Checkbox
              value="manager"
              checked={formData.target.includes('manager')}
              onChange={handleChangeTarget}
            />
          }
        />
        <FormControlLabel
          label="Giáo viên"
          control={
            <Checkbox
              value="teacher"
              checked={formData.target.includes('teacher')}
              onChange={handleChangeTarget}
            />
          }
        />
        <FormControlLabel
          label="Phụ huynh"
          control={
            <Checkbox
              value="parents"
              checked={formData.target.includes('parents')}
              onChange={handleChangeTarget}
            />
          }
        />
        <FormControlLabel
          label="Học sinh"
          control={
            <Checkbox
              value="student"
              checked={formData.target.includes('student')}
              onChange={handleChangeTarget}
            />
          }
        />
      </FormControl>
      <FormControl className={styles.input} fullWidth required>
        <FormLabel>Tùy chọn xuất bản</FormLabel>
        <RadioGroup
          value={publishNow}
          onChange={(e) => setPublishNow(e.target.value)}
        >
          <FormControlLabel label="Lưu nháp" value="0" control={<Radio />} />
          <FormControlLabel label="Xuất bản ngay" value="1" control={<Radio />} />
        </RadioGroup>
      </FormControl>
      <ButtonGroup>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          startIcon={
            saving || publishing || updating ? (
              <CircularProgress size={24} />
            ) : (
              <SaveIcon />
            )
          }
          disabled={saving}
        >
          {saving || updating
            ? 'Đang lưu...'
            : publishing
            ? 'Đang xuất bản...'
            : 'Lưu'}
        </Button>
        <Button onClick={_id ? onCancelled : history.goBack}>Hủy</Button>
      </ButtonGroup>
    </Paper>
  );
}

export default NotificationForm;
