import React, { useContext, useState } from "react";
import {
  Menu,
  MenuItem,
  Avatar,
  Typography,
  Divider,
  makeStyles,
  Chip
} from "@material-ui/core";
import { UserGroupCode } from "../../helper";
import Link from "@material-ui/core/Link";
import ExitIcon from "@material-ui/icons/ExitToApp";
import AccountCircle from "@material-ui/icons/AccountCircleOutlined";
import { Link as RouterLink } from "react-router-dom";
import { AppContext } from "../../App";
import UserDetailDialog from "../user/UserDetailDialog";
import ChangePassword from "../user/ChangePasswd";
// import AssignmentIcon from '@material-ui/icons/AssignmentIndOutlined';
import ImageIcon from "@material-ui/icons/InsertPhotoOutlined";
import KeyIcon from "@material-ui/icons/VpnKeyOutlined";
import EmailIcon from "@material-ui/icons/AlternateEmail";
import PhoneIcon from "@material-ui/icons/Phone";
import UploadAvatar from "../user/UploadAvatar";

const useStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  avatarLg: {
    height: 52,
    width: 52,
    marginRight: theme.spacing(1)
  }
}));

function UserMenu(props) {
  const classes = useStyles();
  const {
    user: { _id: userId, displayName, userGroup, username, tel }
  } = useContext(AppContext);
  const [changePW, setChangePW] = useState(false);
  const [accountDialog, setAccountDialog] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState(false);

  return (
    <div>
      <Menu {...props}>
        <MenuItem>
          <Avatar className={classes.avatarLg} src={"/avatars/" + userId} />
          <Typography style={{ lineHeight: "1.2rem" }}>
            <b>{displayName}</b>
            <br />
            <small>{UserGroupCode[userGroup]}</small>
            <br />
          </Typography>
        </MenuItem>
        <MenuItem style={{ minWidth: 260 }}>
          <Chip
            style={{
              marginRight: 6,
              display: username === tel ? "none" : "flex"
            }}
            icon={<EmailIcon />}
            label={username}
            color="primary"
            size="small"
          />
          <Chip icon={<PhoneIcon />} label={tel} color="primary" size="small" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            props.onClose();
            setAccountDialog(true);
          }}
        >
          <AccountCircle className={classes.leftIcon} />
          Tài khoản
        </MenuItem>
        <Divider />
        {/* <MenuItem
                    onClick={() => {
                        // TODO
                    }}
                >
                    <AssignmentIcon className={classes.leftIcon}/>
                    Hồ sơ cá nhân
                </MenuItem>
                <Divider/> */}
        <MenuItem
          onClick={() => {
            props.onClose();
            setChangeAvatar(true);
          }}
        >
          <ImageIcon className={classes.leftIcon} />
          Đổi ảnh đại diện
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            props.onClose();
            setChangePW(true);
          }}
        >
          <KeyIcon className={classes.leftIcon} />
          Đổi mật khẩu
        </MenuItem>
        <Divider />
        <Link to={"/logout"} component={RouterLink} color={"textPrimary"}>
          <MenuItem>
            <ExitIcon className={classes.leftIcon} />
            Đăng xuất
          </MenuItem>
        </Link>
      </Menu>
      {changePW && (
        <ChangePassword
          id={userId}
          open={changePW}
          onClose={() => setChangePW(false)}
          maxWidth="sm"
          fullWidth
        />
      )}
      {accountDialog && (
        <UserDetailDialog
          id={userId}
          open={accountDialog}
          onClose={() => setAccountDialog(false)}
        />
      )}
      {changeAvatar && (
        <UploadAvatar
          id={userId}
          open={changeAvatar}
          onClose={() => setChangeAvatar(false)}
        />
      )}
    </div>
  );
}

export default UserMenu;
