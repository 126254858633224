import React, { useContext, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import RefreshIcon from "@material-ui/icons/Refresh";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button, Avatar, Hidden } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { AppContext } from "../../App";
import { makeStyles } from "@material-ui/core/styles";
import NotificationButton from "../notifications/NotificationButton";
import UserMenu from "./UserMenu";
import { UserGroupCode } from "../../helper";
import SearchField from "./SearchField";
import d10logo from "../../assets/images/anhngu-diem10.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  avatarLg: {
    height: 52,
    width: 52,
    marginRight: theme.spacing(1),
  },
}));

function TopBar({ onOpenDrawer }) {
  const classes = useStyles();
  const {
    user: { _id: userId, displayName, userGroup },
  } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <AppBar
      position="fixed"
      className={!onOpenDrawer ? "" : classes.appBar}
      color={"default"}
    >
      <Toolbar>
        {onOpenDrawer && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={onOpenDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        )}
        {["admin", "manager"].includes(userGroup) ? (
          <SearchField />
        ) : (
          <img
            src={d10logo}
            alt="Trung tâm Điểm 10"
            style={{
              maxHeight: "64px",
              minWidth: 0,
            }}
          />
        )}
        <div className={classes.title} />
        <NotificationButton />
        <IconButton onClick={() => window.location.reload()}>
          <RefreshIcon />
        </IconButton>
        <Button onClick={(e) => setAnchorEl(e.target)}>
          <Avatar src={userId && `/avatars/${userId}`} />
          <Hidden xsDown>
            <Typography
              align="left"
              style={{
                lineHeight: "1rem",
                textTransform: "none",
                marginLeft: "6px",
              }}
              noWrap
            >
              <b>{displayName}</b>
              <br />
              <small>{UserGroupCode[userGroup]}</small>
              <br />
            </Typography>
          </Hidden>
        </Button>
      </Toolbar>
      <UserMenu
        open={anchorEl != null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
    </AppBar>
  );
}

export default TopBar;
