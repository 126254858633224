import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Typography,
  Collapse,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@material-ui/core';
import { AppContext } from '../../App';
import { UserGroupCode } from '../../helper';
import UserForm from './UserForm';
import CustomLoading from '../utils/CustomLoading';
import CustomErrorMessage from '../utils/CustomErrorMessage';

const GET_USER_DETAIL = gql`
  query GetUserById($id: String!) {
    user: getUserById(id: $id) {
      _id
      username
      displayName
      userGroup
      tel
      goldLimit
    }
  }
`;

function UserDetailDialog({ id, open, onClose }) {
  const [isEdit, setIsEdit] = useState(false);
  const {
    user: {
      _id: userId, userGroup
    },
  } = useContext(AppContext);
  const { data, loading, error } = useQuery(GET_USER_DETAIL, {
    variables: { id },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Thông tin tài khoản</DialogTitle>
      <Collapse in={!isEdit}>
        <DialogContent>
          {loading && <CustomLoading />}
          {!loading && error && <CustomErrorMessage message={error.message} />}
          {!loading && data && !data.user && (
            <CustomErrorMessage info message={'Không có dữ liệu để hiển thị.'} />
          )}
          {!loading && data && data.user && (
            <div>
              <Typography>
                <b>{data.user.displayName.toUpperCase()}</b>
              </Typography>
              <Typography>
                <em>{UserGroupCode[data.user.userGroup]}</em>
              </Typography>
              <Typography>
                <small>
                  <em>Tên đăng nhập: </em>
                </small>
                {data.user.username}
              </Typography>
              <Typography>
                <small>
                  <em>Số ĐT: </em>
                </small>
                {data.user.tel}
              </Typography>
            </div>
          )}
        </DialogContent>
      </Collapse>
      {!loading && data && data.user && (
        <Collapse in={isEdit} mountOnEnter>
          <div style={{ padding: '0px 24px' }}>
            <small>Thay đổi sẽ có hiệu lực từ lần đăng nhập sau.</small>
          </div>
          <UserForm
            user={data.user}
            onCompleted={() => setIsEdit(false)}
            onCancelled={() => setIsEdit(false)}
          />
        </Collapse>
      )}
      <DialogActions>
        {!isEdit && (['admin', 'manager'].includes(userGroup) || userId === id) && (
          <Button color="primary" onClick={() => setIsEdit(true)}>
            Chỉnh sửa
          </Button>
        )}
        {!isEdit && (
          <Button color="primary" onClick={onClose}>
            Đóng
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(UserDetailDialog);
