import React from "react";
import Cropper from "react-cropper";
import { useRef } from "react";
import "cropperjs/dist/cropper.css";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Input,
  Typography,
  Collapse,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

function UploadAvatar(props) {
  const cropper = useRef();
  const fileInput = useRef();
  const [url, setUrl] = useState("");
  const [croppedUrl, setCroppedUrl] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleReset = () => {
    setUrl("");
    setCroppedUrl("");
    setIsSuccess(false);
    setIsPreview(false);
  };

  const crop = () => {
    if (cropper) {
      setCroppedUrl(cropper.current.getCroppedCanvas().toDataURL());
    }
  };

  const onFileChange = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUrl(event.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSubmit = () => {
    const file = fileInput.current.files[0];
    cropper.current.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob);
      setLoading(true);
      setError("");
      axios
        .post("/api/avatar", formData)
        .then(({ data }) => {
          if (data.error) {
            setLoading(false);
            setError(<span>{data.message}</span>);
          } else {
            setIsSuccess(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(
            <span>
              Unexpected error: <br />
              {err.message}
            </span>
          );
        });
    }, file.type);
  };

  return (
    <Dialog
      {...props}
      onClose={() => {
        props.onClose();
        handleReset();
      }}
      fullWidth
    >
      <DialogTitle>Tải lên ảnh đại diện</DialogTitle>
      <DialogContent>
        <Collapse in={!isPreview && !isSuccess}>
          <Typography>Chọn hình để làm ảnh đại diện</Typography>
          <Input
            inputRef={fileInput}
            onChange={onFileChange}
            type="file"
            inputProps={{
              accept: "image/*",
            }}
            fullWidth
            style={{
              marginBottom: 16,
            }}
          />
          <Collapse in={!!url}>
            <Cropper
              ref={cropper}
              src={url}
              style={{ width: "100%", height: 300 }}
              aspectRatio={1}
              guides={false}
              dragMode="crop"
              viewMode={2}
              autoCropArea={1}
              movable={false}
              crop={crop}
            />
          </Collapse>
        </Collapse>
        <Collapse in={isPreview && !isSuccess}>
          <img
            style={{
              margin: "auto",
              width: "100%",
              maxWidth: 300,
              marginBottom: 12,
              display: "block",
            }}
            src={croppedUrl}
            alt="Avatar preview"
          />
          <Typography color="error">
            {error && "Đã xảy ra lỗi, vui lòng thử lại sau!"}
          </Typography>
        </Collapse>
        <Collapse in={isSuccess}>
          <CheckIcon
            color="primary"
            style={{
              margin: "auto",
              display: "block",
              fontSize: 64,
            }}
          />
          <Typography color="primary" align="center" style={{ marginTop: 12 }}>
            Cập nhật thành công!
          </Typography>
        </Collapse>
      </DialogContent>
      <DialogActions>
        {!isSuccess && (
          <Button
            onClick={
              isPreview
                ? () => setIsPreview(false)
                : () => {
                    props.onClose();
                    handleReset();
                  }
            }
          >
            {isPreview ? "Quay lại" : "Huỷ"}
          </Button>
        )}
        {!isSuccess && (
          <Button
            color="primary"
            onClick={() => {
              if (isPreview) {
                handleSubmit();
              } else {
                setIsPreview(true);
              }
            }}
            disabled={!url || loading}
            endIcon={loading ? <CircularProgress size={24} /> : ""}
          >
            {isPreview ? "Lưu" : "Xem trước"}
          </Button>
        )}
        {isSuccess && (
          <Button
            onClick={() => {
              props.onClose();
              handleReset();
              window.location.reload();
            }}
          >
            Đóng
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default UploadAvatar;
