import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from '../../App';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const context = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        context.user ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
