import React, { useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import {
  List,
  ListSubheader,
  ListItem,
  CircularProgress,
  ListItemText,
  LinearProgress,
  Divider,
  Chip,
  Typography,
} from "@material-ui/core";
import gql from "graphql-tag";
import NotificationDialog from "../NotificationDialog";
import { formatDatetime } from "../../../helper";

const GET_MY_NOTIFICATION = gql`
  query GetMyNotification($filter: PublicationFilter, $offset: Int, $limit: Int) {
    notis: getMyNotification(filter: $filter, offset: $offset, limit: $limit) {
      _id
      state
      createdAt
      notification {
        _id
        title
        content
        target
        createdAt
        state
        createdBy {
          _id
          displayName
        }
      }
    }
    numOfNoti: countMyNotification(filter: $filter)
  }
`;

const MARK_AS_READ = gql`
  mutation MarkAsRead($id: String!) {
    noti: markAsRead(id: $id) {
      _id
      state
    }
  }
`;

function NotificationList() {
  const [fetching, setFetching] = useState(false);
  const [selected, setSelected] = useState();
  const [markAsRead] = useMutation(MARK_AS_READ, {
    refetchQueries: ["CountNotification"],
  });
  const { data, loading, fetchMore } = useQuery(GET_MY_NOTIFICATION, {
    variables: {
      offset: 0,
      limit: 5,
    },
  });

  const handleFetchMore = () => {
    setFetching(true);
    fetchMore({
      variables: {
        offset: data.notis.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setFetching(false);
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          notis: [...prev.notis, ...fetchMoreResult.notis],
        };
      },
    });
  };

  return (
    <div>
      <List
        style={{ width: 300, maxWidth: 300 }}
        subheader={
          <ListSubheader style={{ backgroundColor: "white" }}>
            Thông báo của bạn
          </ListSubheader>
        }
      >
        {data && data.notis.length > 0 ? (
          data.notis.map((noti) => (
            <ListItem key={noti._id} button onClick={() => setSelected(noti)}>
              <ListItemText
                disableTypography
                primary={
                  <div>
                    <Typography display="inline">
                      <strong>{noti.notification.title}</strong>
                    </Typography>
                    {noti.state === "new" && (
                      <Chip
                        label="Mới"
                        color="secondary"
                        size="small"
                        style={{
                          marginLeft: "6px",
                        }}
                      />
                    )}
                  </div>
                }
                secondary={
                  <small>
                    {`${formatDatetime(noti.createdAt * 1)}, ${
                      noti.notification.createdBy?.displayName
                    }`}
                  </small>
                }
              />
            </ListItem>
          ))
        ) : loading ? (
          <ListItem style={{ padding: "32px 0px" }}>
            <CircularProgress style={{ margin: "auto", display: "block" }} />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemText primary="Không tìm thấy thông báo!" />
          </ListItem>
        )}
        <Divider />
        {fetching && <LinearProgress variant="query" />}
        {data && data.numOfNoti > data.notis.length && (
          <ListItem button onClick={handleFetchMore}>
            <ListItemText
              secondary={`Thông báo cũ hơn (còn ${data.numOfNoti - data.notis.length})`}
            />
          </ListItem>
        )}
      </List>
      {selected && (
        <NotificationDialog
          open={!!selected}
          onClose={() => {
            markAsRead({ variables: { id: selected._id } });
            setSelected(null);
          }}
          notification={selected.notification}
          publication={selected}
        />
      )}
    </div>
  );
}

export default NotificationList;
