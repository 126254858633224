import React from 'react';
import { useMutation } from 'react-apollo';
import { Typography, Button, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: String!) {
    deletedNoti: deleteNotification(id: $id) {
      _id
    }
  }
`;

function NotificationDelete({ id, onCompleted, onCancelled }) {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteNoti, { loading }] = useMutation(DELETE_NOTIFICATION, {
    refetchQueries: ['GetAllNotification'],
  });

  const handleDelete = async () => {
    try {
      const {
        data: { deletedNoti },
      } = await deleteNoti({
        variables: { id },
      });
      if (deletedNoti) {
        enqueueSnackbar('Xóa thành công!', { variant: 'success' });
        onCompleted();
      } else {
        enqueueSnackbar('Không thể xóa!', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar('Xóa thất bại, ' + err.message, { variant: 'error' });
    }
  };

  return (
    <div>
      <Typography>Bạn muốn xóa thông báo này?</Typography>
      {loading && <LinearProgress />}
      <div
        style={{ marginTop: 12, display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDelete}
          disabled={loading}
        >
          Xóa
        </Button>
        <Button variant="outlined" disabled={loading} onClick={onCancelled}>
          Hủy
        </Button>
      </div>
    </div>
  );
}

export default NotificationDelete;
