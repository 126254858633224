import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

const CHANGE_PASSWORD = gql`
  mutation UpdateUser($id: String!, $newPasswd: String!) {
    changePassword(id: $id, input: { password: $newPasswd }) {
      _id
    }
  }
`;

function ChangePassword(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [newpw, setNewpw] = useState('');
  const [retype, setRetype] = useState('');
  const [updatePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      if (!data.changePassword) {
        return enqueueSnackbar('Đổi mật khẩu thất bại', { variant: 'error' });
      }
      enqueueSnackbar('Đổi mật khẩu thành công', { variant: 'success' });
      props.onClose();
      resetForm();
    },
    onError: () => {
      enqueueSnackbar('Đổi mật khẩu thất bại', { variant: 'error' });
    },
  });

  const resetForm = () => {
    setNewpw('');
    setRetype('');
  };

  return (
    <Dialog {...props}>
      <DialogTitle>Đổi mật khẩu</DialogTitle>
      <DialogContent>
        <form
          id="changepassform"
          onSubmit={(e) => {
            e.preventDefault();
            if (newpw === retype)
              updatePassword({ variables: { id: props.id, newPasswd: newpw } });
          }}
        >
          <TextField
            label="Mật khẩu mới"
            value={newpw}
            type="password"
            margin="dense"
            onChange={(e) => setNewpw(e.target.value)}
            helperText={newpw && newpw.length < 8 ? 'Mật khẩu quá ngắn!' : '  '}
            FormHelperTextProps={{ style: { color: 'red' } }}
            required
            fullWidth
          />
          <TextField
            label="Nhập lại mật khẩu mới"
            value={retype}
            margin="dense"
            type="password"
            onChange={(e) => setRetype(e.target.value)}
            helperText={retype && retype !== newpw ? 'Mật khâủ chưa khớp!!' : '  '}
            FormHelperTextProps={{ style: { color: 'red' } }}
            required
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} startIcon={<CloseIcon />} disabled={loading}>
          Huỷ
        </Button>
        <Button
          form="changepassform"
          type="submit"
          color="primary"
          startIcon={loading ? <CircularProgress size={24} /> : <CheckIcon />}
          disabled={loading}
        >
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePassword;
