import React, { useContext } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import ClassIcon from "@material-ui/icons/ClassOutlined";
import AssessmentIcon from "@material-ui/icons/GradeOutlined";
import GroupIcon from "@material-ui/icons/GroupOutlined";
import BookmarkIcon from "@material-ui/icons/BookmarksOutlined";
import NotifyIcon from "@material-ui/icons/NotificationsOutlined";
import logo from "../../assets/images/anhngu-diem10-tim.png";
import SettingIcon from "@material-ui/icons/SettingsOutlined";
import AccountIcon from "@material-ui/icons/AccountBoxOutlined";
import BusinessIcon from "@material-ui/icons/BusinessCenterOutlined";
import AssistantOutlinedIcon from "@material-ui/icons/AssistantOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  logo: {
    width: "100%",
    margin: "auto",
    display: "block",
  },
  selected: {
    borderLeft: "solid 3px blue",
  },
}));

function SideBar({ onItemClicked }) {
  const classes = useStyles();
  const { title, setTitle } = useContext(AppContext);

  return (
    <div>
      <div className={classes.toolbar}>
        <img className={classes.logo} src={logo} alt={"Trung tâm Điểm 10"} />
      </div>
      <Divider />
      <List>
        <Link to={"/dashboard"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Tổng quan"}
            onClick={() => {
              setTitle("Tổng quan");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Tổng quan"} />
          </ListItem>
        </Link>
        <Link to={"/class"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Sổ đầu bài"}
            onClick={() => {
              setTitle("Sổ đầu bài");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <ClassIcon />
            </ListItemIcon>
            <ListItemText primary={"Sổ đầu bài"} />
          </ListItem>
        </Link>

        <Link to={"/exam"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Sổ điểm"}
            onClick={() => {
              setTitle("Sổ điểm");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary={"Sổ điểm"} />
          </ListItem>
        </Link>

        <Link to={"/student"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Học sinh"}
            onClick={() => {
              setTitle("Học sinh");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={"Học sinh"} />
          </ListItem>
        </Link>

        <Link to={"/teacher"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Giáo viên"}
            onClick={() => {
              setTitle("Giáo viên");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={"Giáo viên"} />
          </ListItem>
        </Link>

        <Link to={"/reward"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Thưởng phạt"}
            onClick={() => {
              setTitle("Thưởng phạt");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <AssistantOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Thưởng phạt"} />
          </ListItem>
        </Link>

        <Link to={"/course"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Khóa học"}
            onClick={() => {
              setTitle("Khóa học");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText primary={"Khóa học"} />
          </ListItem>
        </Link>

        <Link to={"/notification"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Thông báo"}
            onClick={() => {
              setTitle("Thông báo");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <ListItemText primary={"Thông báo"} />
          </ListItem>
        </Link>

        <Link to={"/user"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Người dùng"}
            onClick={() => {
              setTitle("Người dùng");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText primary={"Người dùng"} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List>
        <Link to={"/setting"} component={RouterLink} color={"textPrimary"}>
          <ListItem
            classes={{ selected: classes.selected }}
            button
            selected={title === "Cài đặt"}
            onClick={() => {
              setTitle("Cài đặt");
              onItemClicked();
            }}
          >
            <ListItemIcon>
              <SettingIcon />
            </ListItemIcon>
            <ListItemText primary={"Cài đặt"} />
          </ListItem>
        </Link>
      </List>
    </div>
  );
}

export default SideBar;
