import React, { useState, useContext } from "react";
import {
  TextField,
  MenuItem,
  Paper,
  Button,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import { Mutation } from "react-apollo";
import { makeStyles } from "@material-ui/styles";
import { AppContext } from "../../App";

const CREATE_USER = gql`
  mutation CreateUser($input: [UserInput!]!) {
    createUser(input: $input) {
      _id
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      _id
      username
      displayName
      userGroup
      tel
      goldLimit
      goldSent
      goldReceived
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "16px 24px",
    maxWidth: 600,
    margin: "auto",
  },
}));

function UserForm({ onCompleted, onCancelled, history, user }) {
  const classes = useStyles();
  const [username, setUsername] = useState(user && user.username ? user.username : "");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState(
    user && user.displayName ? user.displayName : ""
  );
  const [userGroup, setUserGroup] = useState(
    user && user.userGroup ? user.userGroup : ""
  );
  const [tel, setTel] = useState(user && user.tel ? user.tel : "");
  const [goldLimit, setGoldLimit] = useState(user?.goldLimit || 0);
  const { enqueueSnackbar } = useSnackbar();
  const { user: currentUser } = useContext(AppContext);

  const isAdmin = currentUser && currentUser.userGroup === "admin";
  const isManager = currentUser && currentUser.userGroup === "manager";

  return (
    <Mutation
      mutation={user ? UPDATE_USER : CREATE_USER}
      refetchQueries={["GetUsers"]}
      onCompleted={(data) => {
        if (!data.updateUser && !data.createUser) {
          return enqueueSnackbar("Đã xảy ra sự cố, vui lòng thử lại sau.", {
            variant: "error",
          });
        }
        enqueueSnackbar((user ? "Cập nhật " : "Tạo ") + "người dùng thành công.", {
          variant: "success",
        });
        if (onCompleted != null) {
          onCompleted();
        } else {
          history.goBack();
        }
      }}
      onError={() => {
        enqueueSnackbar("Đã xảy ra sự cố, vui lòng thử lại sau.", {
          variant: "error",
        });
      }}
    >
      {(mutateUser, { loading }) => (
        <Paper
          component="form"
          className={classes.container}
          style={{ boxShadow: "none" }}
          onSubmit={(e) => {
            e.preventDefault();
            const inputData = {
              username,
              displayName,
              userGroup,
              tel: tel || null,
              goldLimit: +goldLimit,
            };
            if (!user) inputData.password = password;
            mutateUser({
              variables: user
                ? {
                    id: user._id,
                    input: inputData,
                  }
                : {
                    input: inputData,
                  },
            });
          }}
        >
          <TextField
            type="text"
            margin="dense"
            label="Tên hiển thị"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            fullWidth
            required
            helperText="Phải nhiều hơn 6 ký tự"
          />
          {!!user ? (
            ""
          ) : (
            <TextField
              type="text"
              margin="dense"
              label="Tên đăng nhập"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
            />
          )}
          <TextField
            type="text"
            margin="dense"
            label="Số điện thoại"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
            fullWidth
          />
          {!user && (
            <TextField
              type="password"
              margin="dense"
              label="Mật khẩu"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required={!user}
            />
          )}
          {(isAdmin || isManager) && (
            <TextField
              disabled={user && ["teacher", "student", "parent"].includes(user.userGroup)}
              margin="dense"
              label="Nhóm người dùng"
              value={userGroup}
              onChange={(e) => setUserGroup(e.target.value)}
              select
              fullWidth
              required
            >
              <MenuItem value="admin" disabled={!isAdmin}>
                Quản trị viên
              </MenuItem>
              <MenuItem value="manager" disabled={!isAdmin}>
                Quản lý
              </MenuItem>
              <MenuItem value="member">Thành viên</MenuItem>
              <MenuItem value="teacher" disabled>
                Giáo viên
              </MenuItem>
              <MenuItem value="student" disabled>
                Học sinh
              </MenuItem>
              <MenuItem value="parent" disabled>
                Phụ huynh
              </MenuItem>
            </TextField>
          )}
          {isAdmin && (
            <TextField
              type="number"
              margin="dense"
              label="Gold giới hạn"
              value={goldLimit}
              onChange={(e) => setGoldLimit(e.target.value)}
              fullWidth
              required={!user}
              disabled={!["admin", "manager", "teacher"].includes(userGroup)}
            />
          )}
          <ButtonGroup variant="contained" style={{ marginTop: 12 }}>
            <Button
              disabled={loading}
              type="submit"
              startIcon={loading ? <CircularProgress size={24} /> : <CheckIcon />}
              color="primary"
            >
              {"Lưu"}
            </Button>
            <Button
              disabled={loading}
              startIcon={<CloseIcon />}
              onClick={() => {
                if (onCancelled) {
                  onCancelled();
                } else {
                  history.goBack();
                }
              }}
            >
              Hủy
            </Button>
          </ButtonGroup>
        </Paper>
      )}
    </Mutation>
  );
}

export default UserForm;
