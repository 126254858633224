import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { SnackbarProvider } from "notistack";
import axios from "axios";
import Navigation from "./components/navigation";
import PrivateRoute from "./components/utils/private-route";
import Err404 from "./components/utils/err404";
import CloseIcon from "@material-ui/icons/Close";
import { LinearProgress, IconButton } from "@material-ui/core";
import { purple, grey } from "@material-ui/core/colors";

export const AppContext = React.createContext();

const API_URI = "/api/graphql";

const AnswerSheet = lazy(() => import("./components/exam/AnswerSheet"));
const Login = lazy(() => import("./components/user/login"));
const Logout = lazy(() => import("./components/user/logout"));
const Dashboard = lazy(() => import("./components/dashboard"));
const Classes = lazy(() => import("./components/classes"));
const Exam = lazy(() => import("./components/exam"));
const Student = lazy(() => import("./components/student"));
const Teacher = lazy(() => import("./components/teacher"));
const Reward = lazy(() => import("./components/rewards"));
const User = lazy(() => import("./components/user"));
const Setting = lazy(() => import("./components/setting"));
const Courses = lazy(() => import("./components/course"));
const Notification = lazy(() => import("./components/notifications"));

const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    text: {
      primary: grey[900],
    },
  },
});

function AppEntry() {
  const [title, setTitle] = useState("");
  const [user, setUser] = useState({});
  const [api, setApi] = useState(
    new ApolloClient({
      cache: new InMemoryCache(),
      link: createUploadLink({
        uri: API_URI,
        fetch: async (uri, options) => {
          options.url = uri;
          options.data = options.body;
          delete options.body;
          const response = await axios(options);
          response.text = async () => JSON.stringify(response.data);
          response.json = async () => JSON.parse(response.data);
          return response;
        },
      }),
    })
  );
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    axios
      .post("/api/check-session")
      .then(({ data }) => {
        if (data.user) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setUser(null);
      });
  }, []);

  return (
    <ThemeProvider theme={myTheme}>
      <ApolloProvider client={api}>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)} color="inherit">
              <CloseIcon color="inherit" />
            </IconButton>
          )}
        >
          <AppContext.Provider value={{ user, setUser, api, setApi, title, setTitle }}>
            <Router>
              <Suspense fallback={<LinearProgress />}>
                <Switch>
                  <Route exact path={"/login"} component={Login} />
                  <PrivateRoute path={"/"} component={App} />
                </Switch>
              </Suspense>
            </Router>
          </AppContext.Provider>
        </SnackbarProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Navigation>
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          <Route path={"/dashboard"} component={Dashboard} />
          <Route path={"/student/exam/:exam/:point"} component={AnswerSheet} />
          <Route path={"/class"} component={Classes} />
          <Route path={"/exam"} component={Exam} />
          <Route path={"/student"} component={Student} />
          <Route path={"/teacher"} component={Teacher} />
          <Route path={"/reward"} component={Reward} />
          <Route path={"/user"} component={User} />
          <Route path={"/setting"} component={Setting} />
          <Route path={"/course"} component={Courses} />
          <Route path={"/notification"} component={Notification} />
          <Route path={"/logout"} component={Logout} />
          <Redirect exact from={"/"} to={"/dashboard"} />
          <Route component={Err404} />
        </Switch>
      </Suspense>
    </Navigation>
  );
}

export default AppEntry;
