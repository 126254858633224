import React from "react";
import { CircularProgress, Box } from "@material-ui/core";

function CustomLoading() {
  return (
    <Box display="flex" justifyContent="center" pt={3} pb={3}>
      <CircularProgress size={26} disableShrink />
    </Box>
  );
}

export default CustomLoading;
