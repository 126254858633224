import React, { useState } from 'react';
import { IconButton, Popover, Badge } from '@material-ui/core';
import NotIcon from '@material-ui/icons/Notifications';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import NotificationList from './NotificationList';

const COUNT_NOTIFICATION = gql`
  query CountNotification($filter: PublicationFilter) {
    numOfNoti: countMyNotification(filter: $filter)
  }
`;

function NotificationButton() {
  const [anchorEl, setAnchorEl] = useState();
  const { data } = useQuery(COUNT_NOTIFICATION, {
    variables: {
      filter: {
        state: 'new',
      },
    },
  });

  return (
    <div>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Badge badgeContent={data ? data.numOfNoti : 0} color="secondary">
          <NotIcon />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        <NotificationList />
      </Popover>
    </div>
  );
}

export default NotificationButton;
