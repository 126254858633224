export const SubjectCode = {
  t: "Toán",
  l: "Lý",
  h: "Hóa",
  s: "Sinh",
  v: "Văn",
  a: "AV",
};

export const RelationCode = {
  grandpa: "Ông",
  grandma: "Bà",
  dad: "Cha",
  mom: "Mẹ",
  bro: "Anh",
  sis: "Chị",
};

export const GenderCode = {
  male: "Thầy",
  female: "Cô",
};

export const StudyLevelCode = {
  G: "Giỏi",
  K: "Khá",
  TB: "Trung bình",
  Y: "Yếu",
};

export const UserGroupCode = {
  admin: "Quản trị viên",
  manager: "Quản lý",
  member: "Thành viên",
  teacher: "Giáo viên",
  parent: "Phụ huynh",
  student: "Học sinh",
};

export const CommentTypes = {
  absent: {
    color: "orangered",
    label: "Vắng",
  },
  bad: {
    color: "grey",
    label: "Vi phạm",
  },
  good: {
    color: "#1cc88a",
    label: "Khen ngợi",
  },
  info: {
    color: "#36b9cc",
    label: "Thông tin",
  },
  semester: {
    color: "#4a148c",
    label: "Nhận xét học kỳ",
  },
};

export const splitName = (fullName) => {
  const str = fullName.trim();
  const breakIndex = str.match(/\S*$/).index;
  const firstName = str.slice(0, breakIndex).trim();
  const lastName = str.slice(breakIndex).trim();
  return [firstName, lastName];
};

export const formatDatetime = (value, mode = "default") => {
  const date = new Date(value);
  return date.toLocaleTimeString("vi-VN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatDate = (value, mode = "default") => {
  const date = new Date(value);
  return date.toLocaleDateString("vi-VN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const prettyMoney = (value, mode) => {
  switch (mode) {
    case "short":
      if (value >= 1000000) return `${(value / 1000000).toFixed(2)} tr`;
      else if (value >= 1000) return `${(value / 1000).toFixed(0)} ng`;
      else return `${value} đ`;
    case "long":
      let tmp = "";
      let length = `${value}`.length;
      while (length > 0) {
        tmp = `${value}`.slice(length - 3 >= 0 ? length - 3 : 0, length) + "." + tmp;
        length -= 3;
      }
      return tmp.slice(0, tmp.length - 1) + " đồng";
    default:
      if (value >= 1000000) return `${(value / 1000000).toFixed(2)} triệu`;
      else if (value >= 1000) return `${(value / 1000).toFixed(0)} nghìn`;
      else return `${value} đồng`;
  }
};

export function reverseString(str) {
  return str
    ? `${str}`
        .split("")
        .reverse()
        .join("")
    : str;
}

export function formatMoney(value, separator = ".") {
  return value
    ? reverseString(
        reverseString(value)
          .match(/[\d|\w]{1,3}/g)
          .join(separator)
      )
    : value;
}

export function unformatMoney(value) {
  return value ? value.match(/[\d|\w]+/g).join("") : value;
}

export function getTimePeriod(period) {
  const now = new Date();
  const today = {
    start: new Date(now.setHours(0, 0, 0, 0)),
    end: new Date(now.setHours(23, 59, 59, 0)),
  };
  switch (period) {
    case "recent":
      return {
        start: undefined,
        end: undefined,
      };
    case "today":
      return today;
    case "thisWeek":
      return {
        start:
          today.start.getDay() !== 0
            ? new Date(
                today.start.setDate(today.start.getDate() - today.start.getDay() + 1)
              )
            : new Date(today.start.setDate(today.start.getDate() - 6)),
        end: new Date(today.end),
      };
    case "thisMonth":
      return {
        start: new Date(today.start.setDate(1)),
        end: new Date(today.end),
      };
    case "lastMonth":
      return {
        start: new Date(
          new Date(today.start.setDate(1)).setMonth(today.start.getMonth() - 1)
        ),
        end: new Date(today.end),
      };
    default:
      return {
        start: null,
        end: null,
      };
  }
}

export function getCurrentYears() {
  const today = new Date();
  if (today.getMonth() >= 6) {
    return (today.getFullYear() - 2009).toFixed(0);
  } else {
    return (today.getFullYear() - 2009 - 1).toFixed(0);
  }
}

export function removeAccents(text) {
  let str = text;
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/gi, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/gi, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/gi, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/gi, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/gi, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/gi, "y");
  str = str.replace(/đ/gi, "d");
  return str;
}

export function defaultReadClaims(userGroup) {
  switch (userGroup) {
    case "admin":
      return [
        "Student",
        "Parent",
        "Register",
        "Payment",
        "Comment",
        "User",
        "Subject",
        "Course",
        "Class",
        "Exam",
        "Lesson",
        "Teacher",
        "Point",
      ];
    case "manager":
      return [
        "Student",
        "Parent",
        "Register",
        "Payment",
        "Comment",
        "Subject",
        "Course",
        "Class",
        "Exam",
        "Lesson",
        "Teacher",
        "Point",
      ];
    case "teacher":
      return [
        "Subject",
        "Course",
        "Comment",
        "Class",
        "Lesson",
        "Student",
        "Exam",
        "Point",
      ];
    case "parent":
      return [];
    case "student":
      return [
        "Student",
        "Parent",
        "Register",
        "Payment",
        "Comment",
        "Subject",
        "Course",
        "Class",
        "Exam",
        "Lesson",
        "Teacher",
        "Point",
      ];
    default:
      return [];
  }
}

export function defaultWriteClaims(userGroup) {
  switch (userGroup) {
    case "admin":
      return [
        "Student",
        "Parent",
        "Register",
        "Payment",
        "Comment",
        "User",
        "Subject",
        "Course",
        "Class",
        "Exam",
        "Lesson",
        "Teacher",
        "Point",
      ];
    case "manager":
      return [
        "Student",
        "Parent",
        "Register",
        "Payment",
        "Comment",
        "Subject",
        "Course",
        "Class",
        "Exam",
        "Lesson",
        "Teacher",
        "Point",
      ];
    case "teacher":
      return ["Comment", "Lesson", "Point"];
    case "parent":
      return [];
    case "student":
      return ["Comment"];
    default:
      return [];
  }
}
